<template>
  <div class="special-cf page-container ">
    <div class="cf-header">
      <img
        class="header-img"
        src="https://qz-1257093263.cos.ap-shanghai.myqcloud.com/soecialCf2.jpg"
      />
      <div class="header-text">
      
        <div>
          为深入贯彻党的二十大精神，落实党中央、国务院关于高校毕业生就业工作的决策部署，促进高校毕业生高质量充分就业，人力资源社会保障部将开展职引未来--2023年大中城市联合招聘高校毕业生春季专场活动。现就有关事项通知如下:
        </div>
        <div>一、活动名称</div>
        <div style="text-indent: 2em;">
          职引未来--2023年大中城市联合招聘高校毕业生春季专场活动
        </div>
        <div>二、活动时间</div>
        <div style="text-indent: 2em;">2023年3月19日至5月26日</div>
        <div>三、服务对象</div>
        <div style="text-indent: 2em;">
          （一）2023届及往届未就业高校毕业生
        </div>
        <div style="text-indent: 2em;">
          （二）有招聘需求的各类用人单位
        </div>
      </div>
      <div class="header-buttons">
        <a :href="ats" target="_blank">
          <div class="company button">企业参会入口</div>
        </a>

        <div class="resume button" @click="dialogVisible = true">求职者入口</div>
      </div>
    </div>
    <div class="cf-number">
      <div class="number-item">
        <img
          class="number-item-icon"
          src="https://qz-1257093263.cos.ap-shanghai.myqcloud.com/cf_company_icon.png"
        />
        <div class="number-item-text">
          <div style="font-size:18px;font-weight: bold;">{{ countData.company_count||0 }}</div>
          <div style="font-size:14px; width: 6em">参会企业/家</div>
        </div>
      </div>
      <div class="number-item">
        <img
          class="number-item-icon"
          src="https://qz-1257093263.cos.ap-shanghai.myqcloud.com/cf_job_icon.png"
        />
        <div class="number-item-text">
          <div style="font-size:18px;font-weight: bold;">{{countData.company_job_count||0  }}</div>
          <div style="font-size:14px; width: 6em">招聘岗位/个</div>
        </div>
      </div>
      <div class="number-item">
        <img
          class="number-item-icon"
          src="https://qz-1257093263.cos.ap-shanghai.myqcloud.com/cf_views_icon.png"
        />
        <div class="number-item-text">
          <div style="font-size:18px;font-weight: bold;">{{countData.browse_count||0}}</div>
          <div style="font-size:14px; width: 6em">浏览量/人次</div>
        </div>
      </div>
      <div class="number-item">
        <img
          class="number-item-icon"
          src="https://qz-1257093263.cos.ap-shanghai.myqcloud.com/cf_resume_icon.png"
        />
        <div class="number-item-text">
          <div style="font-size:18px;font-weight: bold;">{{countData.resumes_count||0}}</div>
          <div style="font-size:14px; width: 6em">投递简历/份</div>
        </div>
      </div>
    </div>

    <div class="cf-data">
      <SpecialCfFilter :initData="filterData" @change="getFilterData" />
      <JobListWrapper
        cardVersion="new"
        :nums="12"
        pagination
        :filterData="filterData"
        style="margin-top:26px"
        v-if="filterData.curIndex === 0"
        ajaxApi="getActivityCompanyJobList"
      />
      <CompanyListWrapper
        :nums="12"
        v-if="filterData.curIndex === 1"
        pagination
        :filterData="filterData"
        style="margin-top:26px"
        ajaxApi="getActivityCompanyList"

      />
    </div>
    <el-dialog  :visible.sync="dialogVisible" width="360px" center>
      <div style="margin-bottom:15px;text-align:center">
        <div style="margin-bottom:8px">打开微信扫码二维码，或搜索「衢州人才网官网」微信小程序投递</div>
      </div>
      <div style="display:flex;justify-content: center;margin-bottom:8px;">
        <el-image
          style="width:200px;height:200px;overflow:hidden;"
          src="https://qrcode-1306912461.cos.ap-shanghai.myqcloud.com/18b7cacb-7a8e-4a6b-ab22-04be3cd3af17.png"
        >
          <div slot="error"></div>
        </el-image>
        <!-- <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
        </span> -->
      </div>
      <div style="margin-bottom:15px;text-align:center">
        <div style="margin-bottom:8px">职位持续增加中，请继续关注！</div>
        <div style="margin-bottom:8px">疑问咨询：饭老师198 5700 5568（微信同号）</div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import SpecialCfFilter from 'components/specialCf/specialCfFilter';
import JobListWrapper from 'components/jobs/jobListWrapper';
import CompanyListWrapper from 'components/company/companyListWrapper';
import { hrefs } from 'constants/publicData';

export default {
  name: 'specialCf',
  components: { SpecialCfFilter, JobListWrapper, CompanyListWrapper },
  data: () => ({
    filterData: {
      curIndex: 0,
      experiences: '',
      educations: '',
      salary_range: '',
      industry: '',
      search_content: '',
    },
    dialogVisible: false,
    countData:{}
  }),
  methods: {
    getFilterData(data) {
      console.log(data);
      this.filterData = data;
    },
    getData(){
       this.$axiosRequest({
        name: 'getCareerFairCount',
      
      })
        .then((res) => {
          // console.log(res.data);
          if (res.status === 200) {
            this.countData = res.data;
            // console.log(res.data)
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  },
  created(){
this.getData()
  },


  computed: { ats: () => hrefs.ats },
};
</script>
<style lang="scss" scoped>
.special-cf {
  padding-bottom: 16px;
  .cf-header {
    background: #fff;
    margin-bottom: 16px;
    .header {
      &-img {
        width: 100%;
        display: block;
      }
      &-text {
        padding: 16px 24px;
        // margin-bottom: 16px;
        text-align: start;
        font-size: 14px;
        color: #707070;
      }
      &-buttons {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 16px;
        padding-bottom: 16px;

        .button {
          width: 120px;
          height: 30px;
          border-radius: 100px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 8px 16px;
          font-size: 14px;

          cursor: pointer;
          color: #fff;
          &.company {
            background: linear-gradient(92deg, #1d99ff 0%, #0f68b2 100%);
          }
          &.resume {
            background: linear-gradient(158deg, #ffcc2d 0%, #ffb334 60%, #ff9a3a 100%);
          }
        }
      }
    }
  }

  .cf-number {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    margin-bottom: 24px;
    .number-item {
      // width: 288px;
      // height: 100px;
      background: #fff;
      padding: 16px 24px;
      display: flex;
      align-content: center;

      &-icon {
        width: 50px;
        height: 50px;
      }
      &-text {
        margin: 0 52px;
        color: #333;
      }
    }
  }
}
</style>
